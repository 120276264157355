import * as actionTypes from "../actions/referralAction";

const initialState = {
  referrals: [],
  totalReferrals: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ALL_REFERRALS: {
      return {
        ...state,
        referrals: action.data,
      };
    }
    case actionTypes.TOTAL_REFERRALS: {
      return {
        ...state,
        totalReferrals: action.data.total_records,
      };
    }
  }
  return state;
};

export default reducer;
