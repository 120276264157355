import axios from "../../instance";
import * as url from "../../constants/url";

export const GET_URL_LIST = "GET_URL_LIST";
export const GET_URL = "GET_URL";
export const TOTAL_RECORDS = "TOTAL_RECORDS";

export const addingUrl = (data) => {
  return async (dispatch) => {
    const res = await axios.post("/video", data);
    if (res && res.status === 200) {
      return { status: 200 };
    } else if (res && res.error && res.error === true) {
      return res;
    }
  };
};

export const getAllUrl = (data) => {
  return {
    type: GET_URL_LIST,
    data: data,
  };
};

export const totalVideos = (data) => {
  return {
    type: TOTAL_RECORDS,
    data: data,
  };
};

export const gettingAllUrl = (page, search) => {
  return async (dispatch) => {
    const res = await axios.get(`/video?page=${page}&search=${search}`);
    if (res.status && res.status === 200) {
      if (res.data.data.length === 0) {
        dispatch(totalVideos(res.data.meta));
        return { status: 404 };
      } else {
        dispatch(getAllUrl(res.data.data));
        dispatch(totalVideos(res.data.meta));
      }
    }
    return res;
  };
};

export const getUrl = (data) => {
  return {
    type: GET_URL,
    data: data,
  };
};

export const gettingUrl = (id) => {
  return async (dispatch) => {
    const res = await axios.get(`/video/${id}`);
    if (res && res.status && res.status === 200) {
      dispatch(getUrl(res.data.data));
    } else if (res && res.error === true && res.message === "No user id") {
      return 404;
    } else {
      return 500;
    }
  };
};

export const deletingUrl = (id) => {
  return async (dispatch) => {
    const res = await axios.delete(`/video/${id}`);
    if (res && res.status === 200) {
      // dispatch(deleteUser(id));
      return 200;
    } else if (res && res.status && res.status === 500) {
      return 500;
    }
  };
};

export const updatingVideo = (id, data) => {
  return async (dispatch) => {
    const res = await axios.put(`/video/${id}`, data);
    if (res && res.status === 200) {
      return { status: 200 };
    } else {
      return res;
    }
  };
};
