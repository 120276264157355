import * as actionTypes from "../actions/settingsAction";

const initialState = {
  settings: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETTINGS: {
      return {
        ...state,
        settings: action.data,
      };
    }
  }
  return state;
};

export default reducer;
