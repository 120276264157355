import axios from "../../instance";

export const COW_LIST = "COW_LIST";
export const GET_COW = "GET_COW";
export const TOTAL_COW_RECORD = "TOTAL_COW_RECORD";

export const getCowListing = (data) => {
  return {
    type: COW_LIST,
    data: data,
  };
};

export const getTotalRecord = (data) => {
  return {
    type: TOTAL_COW_RECORD,
    data: data,
  };
};

export const gettingCowListing = (page, search) => {
  return async (dispatch) => {
    const res = await axios.get(`/cow?page=${page}&limit=10`);
    // console.log(res);
    if (res.status && res.status === 200) {
      if (res.data.data.length === 0) {
        dispatch(getTotalRecord(res.data.meta));
        return { status: 404 };
      } else {
        dispatch(getCowListing(res.data.data));
        dispatch(getTotalRecord(res.data.meta));
      }
    }
    return res;
  };
};

export const addingCow = (data) => {
  return async (dispatch) => {
    const res = await axios.post("/cow", data);
    if (res && res.status === 200) {
      return { status: 200 };
    } else {
      return res;
    }
  };
};

export const getCow = (data) => {
  return {
    type: GET_COW,
    data: data,
  };
};

export const gettingCow = (id) => {
  return async (dispatch) => {
    const res = await axios.get(`/cow/${id}`);
    if (res && res.status && res.status === 200) {
      dispatch(getCow(res.data.data));
    } else if (res.code && res.code === 404) {
      return 404;
    } else {
      return 500;
    }
  };
};

export const deletingCow = (id) => {
  return async (dispatch) => {
    const res = await axios.delete(`/cow/${id}`);
    if (res && res.status === 200) {
      // dispatch(deleteUser(id));
      return 200;
    } else if (res && res.status && res.status === 500) {
      return 500;
    }
  };
};

export const updatingCow = (id, data) => {
  return async (dispatch) => {
    const res = await axios.put(`/cow/${id}`, data);
    return res;
  };
};
