export const gettingAllOrders = (
  page = 1,
  search ,
  limit = 10,
  day,
  status, date
) => {
  if (search !== null) {
    return `/order?limit=${limit}&page=${
      page * 1
    }&search=${search}&day=${day}&status=${status}&date=${date}`;
  } else {
    return `/order?limit=${limit}&page=${page * 1}&day=${day}&status=${status}&date=${date}`;
  }
};

export const gettingWaitingList = (
  page = 1,
  search = null,
  limit = 10,
  day
) => {
  if (search !== null) {
    return `/waiting?limit=${limit}&page=${page * 1}&search=${search}`;
  } else {
    return `/waiting?limit=${limit}&page=${page * 1}`;
  }
};

export const gettingAllCustomers = (page = 1, search = null, limit = 10) => {
  if (search !== null) {
    return `/customer?limit=${limit}&page=${page * 1}&search=${search}`;
  } else {
    return `/customer?limit=${limit}&page=${page * 1}`;
  }
};

export const gettingAllReferrals = (page = 1, search = null) => {
  if (search !== null) {
    return `/referral?limit=10&page=${page * 1}&search=${search}`;
  } else {
    return `/referral?limit=10&page=${page * 1}`;
  }
};

export const gettingDriverListing = (page = 1, search = null, limit = 10) => {
  if (search !== null) {
    return `/driver?limit=${limit}&page=${page * 1}&search=${search}`;
  } else {
    return `/driver?limit=${limit}&page=${page * 1}`;
  }
};
export const changingOrderStatus = (id) => {
  return `/admin/change-order-status/${id}`;
};
export const updatingOrder = (id) => {
  return `/order/${id}`;
};

export const gettingOrder = (id) => {
  return `/order/${id}`;
};

export const deletingOrder = (id) => {
  return `/order/${id}`;
};

//////////////////////////// Constant URL /////////////////////////////////////////////

export const addingOrders = "/order";
