import * as actionTypes from "../actions/waitingListAction";

const initialState = {
  waitingList: [],
  totalWaitingData: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.WAITING_LIST_DATA: {
      return {
        ...state,
        waitingList: action.data,
      };
    }
    case actionTypes.TOTAL_WAITING_DATA: {
      return {
        ...state,
        totalWaitingData: action.data.total_records,
      };
    }
    default:
      return { ...state };
  }
};

export default reducer;
