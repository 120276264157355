import axios from "../../instance";

export const RATING_LIST = "RATING_LIST";
export const TOTAL_RATING_RECORD = "TOTAL_RATING_RECORD";

export const getTotalRecord = (data) => {
  return {
    type: TOTAL_RATING_RECORD,
    data: data,
  };
};

export const getRatingList = (data) => {
  return {
    type: RATING_LIST,
    data: data,
  };
};

export const gettingRatingList = (page = 1, search, limit = 10) => {
  return async (dispatch) => {
    const res = await axios.get(`/ratings?limit=${limit}&page=${page}`);
    if (res.status && res.status === 200) {
      if (res.data.data.length === 0) {
        dispatch(getTotalRecord(res.data.meta));
        return { status: 404 };
      } else {
        dispatch(getRatingList(res.data.data));
        dispatch(getTotalRecord(res.data.meta));
      }
    }
    return res;
  };
};
