import * as actionTypes from "../actions/cowAction";

const initialState = {
  cowList: [],
  cow: "",
  totalCowRecords: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COW_LIST: {
      return {
        ...state,
        cowList: action.data,
      };
    }
    case actionTypes.GET_COW: {
      return {
        ...state,
        cow: action.data,
      };
    }
    case actionTypes.TOTAL_COW_RECORD: {
      return {
        ...state,
        totalCowRecords: action.data.total_records,
      };
    }
  }
  return state;
};

export default reducer;
