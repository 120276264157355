import * as actionTypes from "../actions/ratingAction";

const initialState = {
  allRatings: [],
  totalRatingRecords: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RATING_LIST: {
      return {
        ...state,
        allRatings: action.data,
      };
    }
    case actionTypes.TOTAL_RATING_RECORD: {
      return {
        ...state,
        totalRatingRecords: action.data.total_records,
      };
    }
  }
  return state;
};

export default reducer;
