import axios from "./../../instance";

export const GET_DATA = "GET_DATA";

export const getSalesData = (data) => {
  return {
    type: GET_DATA,
    data: data,
  };
};

export const gettingSalesData = () => {
  return async (dispatch) => {
    const res = await axios.get("/analytics");
    if (res.status === 200) {
      dispatch(getSalesData(res.data.data));
    }
    return res;
  };
};
