import * as actionTypes from "../actions/orderAction";

const initialState = {
  orders: [],
  totalRecord: "",
  todayOrders: "",
  weeklyOrders: "",
  order: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ORDERS: {
      return {
        ...state,
        orders: action.data,
      };
    }
    case actionTypes.GET_ORDER: {
      return {
        ...state,
        order: action.data,
      };
    }
    case actionTypes.GET_PAGE: {
      return {
        ...state,
        totalRecord: action.data.total_records,
      };
    }
    case actionTypes.TODAY_ORDERS: {
      return {
        ...state,
        todayOrders: action.data,
      };
    }
    case actionTypes.WEEK_ORDERS: {
      return {
        ...state,
        weeklyOrders: action.data,
      };
    }
    default:
      return { ...state };
  }
};

export default reducer;
