import * as actionTypes from "../actions/driverAction";

const initialState = {
  driversList: [],
  driver: "",
  totalDriversRecords: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DRIVERS_LIST: {
      return {
        ...state,
        driversList: action.data,
      };
    }
    case actionTypes.GET_DRIVER: {
      return {
        ...state,
        driver: action.data,
      };
    }
    case actionTypes.TOTAL_DRIVER_RECORD: {
      return {
        ...state,
        totalDriversRecords: action.data.total_records,
      };
    }
  }
  return state;
};

export default reducer;
