import axios from "../../instance";
import * as url from "../../constants/url";

export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_TOTAL_RECORD = "GET_TOTAL_RECORD";

export const addingCustomer = (data) => {
  return async (dispatch) => {
    const res = await axios.post("/admin/customer", data);
    return res;
  };
};

export const updatingCustomer = (id, data) => {
  return async (dispatch) => {
    const res = await axios.put(`customer/${id}`, data);
    return res;
  };
};

export const getAllCustomers = (data) => {
  return {
    type: GET_ALL_CUSTOMERS,
    data: data,
  };
};

export const getTotalRecord = (data) => {
  return {
    type: GET_TOTAL_RECORD,
    data: data,
  };
};

export const gettingAllCustomers = (page, search, limit) => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingAllCustomers(page, search, limit));
    if (res.status && res.status === 200) {
      if (res.data.data.length === 0) {
        dispatch(getTotalRecord(res.data.meta));
        return { status: 404 };
      } else {
        dispatch(getAllCustomers(res.data.data));
        dispatch(getTotalRecord(res.data.meta));
      }
    }
    return res;
  };
};

export const getCustomer = (data) => {
  return {
    type: GET_CUSTOMER,
    data: data,
  };
};

export const gettingCustomer = (id) => {
  return async (dispatch) => {
    const res = await axios.get(`/customer/${id}`);
    if (res.status && res.status === 200) {
      dispatch(getCustomer(res.data.data));
    }
    return res;
  };
};

export const updatingStatus = (id, status) => {
  return async (dispatch) => {
    const data = {
      status: status * 1 === 0 ? "1" : "0",
    };
    const res = await axios.put(`/customer/${id}`, data);
    if (res && res.status === 200) {
      return { status: 200 };
    } else if (res && res.status === 500) {
      return { status: 500 };
    }
  };
};
