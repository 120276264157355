import axios from "../../instance";
import * as url from "../../constants/url";

export const WAITING_LIST_DATA = "WAITING_LIST_DATA";
export const TOTAL_WAITING_DATA = "TOTAL_WAITING_DATA";

const getWaitingList = (data) => {
  return {
    type: WAITING_LIST_DATA,
    data: data,
  };
};

const getPage = (data) => {
  return {
    type: TOTAL_WAITING_DATA,
    data: data,
  };
};

export const gettingWaitingList = (page, search, limit, day) => {
  return async (dispatch) => {
    const res = await axios.get(
      url.gettingWaitingList(page, search, limit, day)
    );
    if (res.status === 200) {
      if (res.data.data.length === 0) {
        dispatch(getPage(res.data.meta));
        return { status: 404 };
      } else {
        dispatch(getWaitingList(res.data.data));
        dispatch(getPage(res.data.meta));
      }
    }
    return res;
  };
};
