import axios from "../../instance";
import * as url from "../../constants/url";

export const GET_ORDERS = "GET_ORDERS";
export const GET_PAGE = "GET_PAGE";
export const TODAY_ORDERS = "TODAY_ORDERS";
export const WEEK_ORDERS = "WEEK_ORDERS";
export const GET_ORDER = "GET_ORDER";

const getOrders = (data) => {
  return {
    type: GET_ORDERS,
    data: data,
  };
};

const getPage = (data) => {
  return {
    type: GET_PAGE,
    data: data,
  };
};

export const gettingAllOrders = (page, search, limit, day, status, date) => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingAllOrders(page, search, limit, day, status, date));
    if (res.status === 200) {
      if (res.data.data.length === 0) {
        dispatch(getPage(res.data.meta));
        return { status: 404 };
      } else {
        dispatch(getOrders(res.data.data));
        dispatch(getPage(res.data.meta));
      }
    }
    return res;
  };
};

export const getTodayOrders = (data) => {
  return {
    type: TODAY_ORDERS,
    data: data,
  };
};

export const gettingTodayOrders = (page, search, limit, day) => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingAllOrders(page, search, limit, day));
    if (res.status === 200) {
      if (res.data.data.length === 0) {
        return { status: 404 };
      } else {
        dispatch(getTodayOrders(res.data.data));
      }
    }
    return res;
  };
};

export const getWeekOrders = (data) => {
  return {
    type: WEEK_ORDERS,
    data: data,
  };
};

export const gettingWeeklyOrders = (page, search, limit, day) => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingAllOrders(page, search, limit, day));
    if (res.status === 200) {
      if (res.data.data.length === 0) {
        return { status: 404 };
      } else {
        dispatch(getWeekOrders(res.data.data));
      }
    }
    return res;
  };
};

export const addingOrders = (data) => {
  return async(dispatch) => {
    const res = await axios.post(url.addingOrders, data);
    // console.log(res)
    return res
  }
}

export const changingOrderStatus = (id,data) => {
  return async(dispatch) => {
    // console.log(data, id)
    const res = await axios.patch(url.changingOrderStatus(id), data);
    // console.log(res)
    return res
  }
}

export const updatingOrder = (id, data) => {
  return async(dispatch) => {
    const res = await axios.patch(url.updatingOrder(id), data);
    // console.log(res);
    return res
  }
}

export const getOrder = (data) => {
  return {
    type: GET_ORDER,
    data: data
  }
}

export const gettingOrder = (id) => {
  return async(dispatch) => {
    const res = await axios.get(url.gettingOrder(id))
    // console.log(res)
    if (res.status === 200) {
      if (res.data.data.length === 0) {
        return { status: 404 };
      } else {
        dispatch(getOrder(res.data.data));
      }
    }
    return res;
  }
}

export const deletingOrder = (id) => {
  return async(dispatch) => {
    const res = await axios.delete(url.deletingOrder(id))
    return res
  }
}