import axios from "../../instance";

export const SETTINGS = "SETTINGS";

export const getSettings = (data) => {
  return {
    type: SETTINGS,
    data: data,
  };
};

export const gettingSettings = () => {
  return async (dispatch) => {
    const res = await axios.get("/settings");
    if (res.status && res.status === 200) {
      dispatch(getSettings(res.data.data));
    }
    return res;
  };
};

export const updatingSettings = (data) => {
  return async (dispatch) => {
    const res = await axios.put("/settings", data);
    return res;
  };
};
