import * as actionTypes from "../actions/customerAction";

const initialState = {
  customers: [],
  customer: "",
  totalRecord: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_CUSTOMERS: {
      return {
        ...state,
        customers: action.data,
      };
    }
    case actionTypes.GET_CUSTOMER: {
      return {
        ...state,
        customer: action.data,
      };
    }
    case actionTypes.GET_TOTAL_RECORD: {
      return {
        ...state,
        totalRecord: action.data.total_records,
      };
    }
  }
  return state;
};

export default reducer;
