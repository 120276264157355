import * as constants from "../../constants/appConstants";
import * as url from "../../constants/urlConstants";
import axios from "../../instance";

export const loggingUser = (data) => {
  return async (dispatch) => {
    const res = await axios.post(url.loggingUser, data);
    if (res.status === 200) {
      localStorage.setItem("keshavi-token", res.data.data.token);
    }
    return res;
  };
};

export const loggingOut = () => {
  return async (dispatch) => {
    await localStorage.removeItem("keshavi-token");
    return true;
  };
};
