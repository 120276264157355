import axios from "../../instance";
import * as url from "../../constants/url"

export const DRIVERS_LIST = "DRIVERS_LIST";
export const GET_DRIVER = "GET_DRIVER";
export const TOTAL_DRIVER_RECORD = "TOTAL_DRIVER_RECORD";

export const getDriverListing = (data) => {
  return {
    type: DRIVERS_LIST,
    data: data,
  };
};

export const getTotalRecord = (data) => {
  return {
    type: TOTAL_DRIVER_RECORD,
    data: data,
  };
};

export const gettingDriverListing = (page, search) => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingDriverListing(page,search));
    // console.log(res);
    if (res.status && res.status === 200) {
      if (res.data.data.length === 0) {
        dispatch(getTotalRecord(res.data.meta));
        return { status: 404 };
      } else {
        dispatch(getDriverListing(res.data.data));
        dispatch(getTotalRecord(res.data.meta));
      }
    }
    return res;
  };
};

export const addingDriver = (data) => {
  return async (dispatch) => {
    const res = await axios.post("/driver", data);
    if (res && res.status === 200) {
      return { status: 200 };
    } else {
      return res;
    }
  };
};

export const getDriver = (data) => {
  return {
    type: GET_DRIVER,
    data: data,
  };
};

export const gettingDriver = (id) => {
  return async (dispatch) => {
    const res = await axios.get(`/driver/${id}`);
    // console.log(res)
    if (res && res.status && res.status === 200) {
      dispatch(getDriver(res.data.data));
    } else if (res.code && res.code === 404) {
      return 404;
    } else {
      return 500;
    }
  };
};

export const deletingDriver = (id) => {
  return async (dispatch) => {
    const res = await axios.delete(`/driver/${id}`);
    // console.log(res)
    if (res && res.status === 200) {
      // dispatch(deleteUser(id));
      return 200;
    } else if (res && res.status && res.status === 500) {
      return 500;
    }
  };
};

export const updatingDriver = (id, data) => {
  return async (dispatch) => {
    const res = await axios.put(`/driver/${id}`, data);
    // console.log(res)
    return res;
  };
};
