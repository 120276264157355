import axios from "../../instance";
import * as url from "../../constants/url";

export const ALL_REFERRALS = "ALL_REFERRALS";
export const TOTAL_REFERRALS = "TOTAL_REFERRALS";

export const getAllReferrals = (data) => {
  return {
    type: ALL_REFERRALS,
    data: data,
  };
};

export const getTotalRecord = (data) => {
  return {
    type: TOTAL_REFERRALS,
    data: data,
  };
};

export const gettingAllReferrals = (page, search) => {
  return async (dispatch) => {
    const res = await axios.get(url.gettingAllReferrals(page, search));
    if (res.status && res.status === 200) {
      if (res.data.data.length === 0) {
        dispatch(getTotalRecord(res.data.meta));
        return { status: 404 };
      } else {
        dispatch(getAllReferrals(res.data.data));
        dispatch(getTotalRecord(res.data.meta));
      }
    }
    return res;
  };
};
