import { switchStatement } from "@babel/types";
import * as actionTypes from "../actions/youtubeAction";

const initialState = {
  allVideos: [],
  video: "",
  totalRecords: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_URL_LIST: {
      return {
        ...state,
        allVideos: action.data,
      };
    }
    case actionTypes.GET_URL: {
      return {
        ...state,
        video: action.data,
      };
    }
    case actionTypes.TOTAL_RECORDS: {
      return {
        ...state,
        totalRecords: action.data.total_records,
      };
    }
  }
  return state;
};

export default reducer;
