import axios from "axios";
import * as constants from "./constants/appConstants";
import * as url from "./constants/urlConstants";

const instance = axios.create({
  // baseURL: "http://5fc1f26f427d.ngrok.io/QuotaGames/api/auth",
  baseURL: "https://api.kmilk.in",
  // headers: {
  //   // Authorization: constants.token ? `Bearer ${constants.token}` : null,
  //   Authorization: constants.token
  //     ? `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NSwicm9sZSI6ImN1c3RvbWVyIiwiaWF0IjoxNjI3MzcxODE1fQ.3GX2--C5UfuRO2auz255M4taYsycfPUXyHhvWdpHQp8`
  //     : null,
  //   "Content-Type": "application/json",
  //   accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // },
});

const requestHandler = (request) => {
  request.headers["Authorization"] = `${constants.token}`;
  return request;
};

const forgetHandlerOne = (request) => {
  request.headers["Authorization"] = `${localStorage.getItem(
    "keshavi-forgot-token-1"
  )}`;
  return request;
};

const forgetHandlerTwo = (request) => {
  request.headers["Authorization"] = `${localStorage.getItem(
    "keshavi-forgot-token-2"
  )}`;
  return request;
};

instance.interceptors.request.use((request) => {
  if (request.url === url.forgetPasswordOtp) {
    forgetHandlerOne(request);
  } else if (request.url === url.resetForgetPassword) {
    forgetHandlerTwo(request);
  } else {
    requestHandler(request);
  }
  return request;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    // console.log(
    //   err && err.response && err.response.data ? err.response.data : null
    // );
    // console.log(err.response);
    if (
      err.response &&
      (err.response.data.code === 401 || err.response.data.code === 403)
    ) {
      return unAutherized();
    }
    return err && err.response && err.response.data
      ? err.response.data
      : { status: 500 };
  }
);

const unAutherized = () => {
  if (localStorage.getItem("keshavi-token")) {
    localStorage.removeItem("keshavi-token");
    window.location.href = "/login";
  }
};

export default instance;
